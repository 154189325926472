import { Component, OnInit, ElementRef } from "@angular/core";

import { CoreService } from "../core.service";
import { Phone } from "src/app/interfaces/phone";
import { CourseGroup } from "src/app/interfaces/course-group";
import { Constants } from "src/app/shared/constants.service";
import { CourseGroupPos } from "src/app/interfaces/course-group-pos";
import { CartService } from "src/app/services/cart.service";

@Component({
  selector: "cm-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  phones: Phone[];
  courseGroups: CourseGroup[];
  courseGroupsPos: CourseGroupPos[];
  menuMobileOpen: boolean = false;
  aboutUsLinks: boolean = false;
  contactUsLinks: boolean = false;
  graduacaoLinks: boolean = false;
  coursesLinks: boolean = false;
  coursesLinksPos: boolean = false;
  coursesLinksOnline: boolean = false;
  currentGroup: number = 0;
  about: string = "Sobre nós";
  isTerzius: boolean;
  totalItemsInCart = 0;
  digital_courses: string = "https://helpsmed.com.br/cursos-recomendados";

  constructor(
    private _coreService: CoreService,
    private elementRef: ElementRef,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this._getPhoneList();
    this._getCourseGroupList();
    this._getCourseGroupListPos();
    this._getTotalItemsInCart();

    this.cartService.cartUpdated.subscribe(() => {
      this.totalItemsInCart = this.cartService.getTotalItensInCart();
    });
  }

  // ----- PUBLIC METHODS
  toggleMenuMobile(): void {
    this.menuMobileOpen = !this.menuMobileOpen;
    this.aboutUsLinks = false;
    this.contactUsLinks = false;
    this.graduacaoLinks = false;
    this.coursesLinks = false;
    this.coursesLinksPos = false;
    this.coursesLinksOnline = false;
    this.currentGroup = 0;
  }

  cleanPhoneNumber(phone: any): string {
    return phone.replace(/\D/g, "");
  }

  teste(id: number): void {
    if (this.currentGroup == id) {
      this.currentGroup = 0;
    } else {
      this.currentGroup = id;
    }
  }

  bindMenuLinks() {
    var x = this.elementRef.nativeElement.querySelectorAll(
      ".cm-header__submenu-item"
    );
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].addEventListener("click", this.closeMenu.bind(this));
    }
  }

  closeMenu(event) {
    event.preventDefault();
    var x = this.elementRef.nativeElement.querySelectorAll(
      ".cm-header__menu-item--has-child"
    );
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].classList.add("closed");
    }

    setTimeout(() => {
      try {
        for (i = 0; i < x.length; i++) {
          x[i].classList.remove("closed");
        }
      } catch (err) {}
    }, 300);
  }

  // ----- PRIVATE METHODS
  private _getPhoneList(): void {
    this._coreService.getPhones().subscribe((response: any) => {
      this.phones = response;
    });
  }

  private _getCourseGroupListPos(): void {
    this._coreService.getCourseGroupsPos().subscribe((response: any) => {
      response.map((group) => {
        group["course_types"] = chunk(group.course_types, 8);
      });

      function chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize)
          R.push(arr.slice(i, i + chunkSize));
        return R;
      }
      this.courseGroupsPos = response;
      setTimeout(() => {
        try {
          this.bindMenuLinks();
        } catch (err) {}
      }, 1);
    });
  }

  private _getCourseGroupList(): void {
    this._coreService.getCourseGroups().subscribe((response: any) => {
      response.map((group) => {
        group["course_types"] = chunk(group.course_types, 8);
      });

      function chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize)
          R.push(arr.slice(i, i + chunkSize));
        return R;
      }
      this.courseGroups = response;
      setTimeout(() => {
        try {
          this.bindMenuLinks();
        } catch (err) {}
      }, 1);
    });
  }

  private _getTotalItemsInCart() {
    this.totalItemsInCart = this.cartService.getTotalItensInCart();
  }

  redirectPdf() {
    window.open(
      "https://terzius-documentos.s3.us-east-2.amazonaws.com/edital_de_processo_seletivo.pdf",
      "_blank"
    );
  }

  redirectInscricao() {
    window.open("https://conteudo.terzius.com.br/graduacao", "_blank");
  }

  redirectWork() {
    window.open(
      "https://www.indeedjobs.com/curem-cursos-de-urg%C3%AAncia-e-emerg%C3%AAncia/_hl/pt?cpref=JXWAtnzf3XW5aRnY2g_zoqF8RN6a-Vzuj3tmSJzrcls",
      "_blank"
    );
  }
}
